import * as React from 'react'
import Link from '../components/Link'
import logoSource from '../images/logo.svg'
import { useStaticQuery, graphql } from 'gatsby'
import { ThemeContext } from '../contexts/ThemeContext'

const Header = (props) => {
  const [hide, setHide] = React.useState(true)
  let { location } = props
  const [activeMenu, setActiveMenu] = React.useState('')
  // const [hover, setHover] = React.useState(false)
  const { theme, setTheme } = React.useContext(ThemeContext)
  const data = useStaticQuery(graphql`
    query {
      categories: allCategory(limit: 1000) {
        nodes {
          id
          title
          slug
          css
        }
      }
    }
  `)
  const [categories, setCategories] = React.useState(
    data.categories.nodes || []
  )
  // const handleThemeToggle = () => {
  //   if (theme === 'light') {
  //     setTheme('dark')
  //   } else {
  //     setTheme('light')
  //   }
  // }

  const toggleMobileMenu = () => {
    setHide(!hide)
  }
  React.useEffect(() => {
    setActiveMenu(location.pathname)
    setHide(true)
    const getStarted = {
      id: '12',
      title: 'Get Started',
      slug: '/open-processes',
      css: 'bg-gradient-to-r from-blue-900 to-teal-neutral',
    }
    setCategories([getStarted, ...data.categories.nodes])
  }, [location.pathname, data.categories.nodes])
  return (
    <header className="py-4 z-50 relative">
      <div
        className={`container-md flex flex-row justify-between items-center  ${
          theme === 'dark' ? 'text-white' : ''
        }`}
      >
        <Link to="/">
          <div className="flex items-center">
            <img src={logoSource} className="w-8" alt="Pairing.dev logo" />
            <span
              className={` ${
                activeMenu === '/'
                  ? 'text-transparent from-teal-dark to-teal-neutral'
                  : ''
              } ml-2 font-bold text-xl bg-gradient-to-r bg-clip-text  hover:text-transparent  hover:from-teal-dark hover:to-teal-neutral `}
            >
              Pairing.dev
            </span>
          </div>
        </Link>
        <nav className="hidden flex-row items-center h-12 lg:flex">
          <Link
            to="/"
            className={`font-bold  items-center inline ml-10 bg-gradient-to-r bg-clip-text  hover:text-transparent hover:from-accent-dark hover:to-accent-light ${
              activeMenu === '/'
                ? 'text-transparent from-accent-dark to-accent-light font-black '
                : ''
            }`}
          >
            Home
          </Link>
          <Link
            to="/casestudies"
            className={`font-medium font-display text-lg ml-10  bg-clip-text bg-gradient-to-r hover:text-transparent  hover:from-teal-dark hover:to-blue-500 ${
              activeMenu.startsWith('/casestudies')
                ? 'text-transparent bg-clip-text bg-gradient-to-r from-teal-dark to-blue-500'
                : ''
            }`}
          >
            Case Studies
          </Link>
          <Link
            to="/about"
            className={`font-medium font-display text-lg ml-10 bg-clip-text bg-gradient-to-r hover:text-transparent hover:from-green-dark hover:to-green-light  ${
              activeMenu === '/about'
                ? 'text-transparent from-green-dark to-green-light font-black'
                : ''
            }`}
          >
            About
          </Link>
          {/* <Link
            to="/careers"
            className={`font-medium font-display text-lg ml-10   bg-clip-text bg-gradient-to-r hover:text-transparent hover:from-primary_color hover:to-secodary_color  ${
              activeMenu === '/careers'
                ? 'text-transparent  from-primary_color to-secodary_color font-black'
                : ''
            }`}
          >
            Careers
          </Link> */}

          <Link
            to="/blog"
            className={`font-medium font-display text-lg ml-10  bg-clip-text bg-gradient-to-r hover:text-transparent  hover:from-orange-dark hover:to-orange-light ${
              activeMenu.startsWith('/blog')
                ? 'text-transparent from-orange-dark to-orange-light font-black'
                : ''
            }`}
          >
            Blog
          </Link>

          {/* <div
            onClick={(e) => handleThemeToggle()}
            className="flex items-center ml-4  cursor-pointer"
          >
            {theme === 'dark' ? (
              <img src={sun} className="ml-2 text-white w-8" alt="Moon icon" />
            ) : (
              <img
                src={moon_dark}
                className="ml-2 text-white w-6"
                alt="Moon icon"
              />
            )}
          </div> */}
          <div>
            <Link
              variant="primary"
              to="/book"
              type="btn"
              size="medium"
              className="ml-8 font-semibold"
            >
              Get a Price
            </Link>
          </div>
        </nav>
        <div className="lg:hidden flex items-center">
          <Link
            variant="primary"
            to="/book"
            type="btn"
            size="medium"
            className="ml-8 mr-4 text-xs py-1 font-semibold"
          >
            Get a Price
          </Link>
          <button
            onClick={(e) => toggleMobileMenu()}
            className="outline-none mobile-menu-button"
            aria-label="Mobile Menu Button"
            role="menu"
          >
            <svg
              className="w-6 h-6 text-gray-500"
              x-show="!showMenu"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
          <div
            className={`${
              hide ? 'hidden' : 'flex'
            } w-full mx-2 mobile-menu shadow-2xl px-8 absolute top-24 right-0 rounded-md ${
              theme === 'dark' ? 'bg-dark_bg-light' : 'bg-white'
            }`}
          >
            <ul className="flex flex-col justify-flex-end font-bold">
              <Link
                to="/"
                className={`relative my-4 h-10 flex items-center rounded-l-xl cursor-pointer  ${
                  activeMenu === '/'
                    ? 'border-r-0 text-transparent bg-clip-text bg-gradient-to-r from-accent-dark to-accent-light '
                    : ''
                }`}
              >
                Home
              </Link>
              <Link
                to="/about"
                className={`relative my-4 h-10 flex items-center  cursor-pointer  ${
                  activeMenu === '/about'
                    ? 'bg-primary_color  border-r-0 text-transparent bg-clip-text bg-gradient-to-r from-green-dark to-green-light'
                    : ''
                }`}
              >
                About
              </Link>
              {/* <Link
                to="/careers"
                className={`relative my-4 h-10 flex items-center  cursor-pointer  ${
                  activeMenu === '/careers'
                    ? 'bg-primary_color  border-r-0 text-transparent bg-clip-text bg-gradient-to-r from-primary_color to-secodary_color'
                    : ''
                }`}
              >
                Careers
              </Link> */}
              <Link
                to="/blog"
                className={`relative my-4 h-10 flex items-center  cursor-pointer  ${
                  activeMenu === '/blog'
                    ? 'bg-primary_color  border-r-0 text-transparent bg-clip-text bg-gradient-to-r from-orange-dark to-orange-light'
                    : ''
                }`}
              >
                Blog
              </Link>
              <Link
                to="/casestudies"
                className={`relative my-4 h-10 flex items-center  cursor-pointer  ${
                  activeMenu === '/casestudies'
                    ? 'bg-primary_color  border-r-0 text-transparent bg-clip-text bg-gradient-to-r from-orange-dark to-orange-light'
                    : ''
                }`}
              >
                Case Studies
              </Link>
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
