import * as React from 'react'
import Link from '../components/Link'
import logoSource from '../images/logo.svg'
import location_icon from '../images/icons/location-icon.svg'
import email_icon from '../images/icons/email-icon.svg'
import phone_icon from '../images/icons/phone-icon.svg'

const Footer = () => (
  <footer className="border-t bg-dark_bg-normal border-gray-100 dark:border-dark_bg-light  text-gray-200 mt-24">
    <div className="flex flex-wrap md:justify-center items-center px-4 md:px-16 py-6">
      <div className="flex justify-center items-center">
        <img src={logoSource} className="w-10" alt="RandomKPI logo" />
        <span className="text-2xl ml-2 font-bold">Pairing.dev</span>
      </div>
      {/* <div className='flex flex-col md:flex-row'>
        <Link to='/open-processes' className='my-6 md:my-0 mx-4 cursor-pointer'>
          Open Processes
        </Link>
        <Link to='/about' className='mx-4 my-6 md:my-0 cursor-pointer'>
          About
        </Link>
        <Link to='/careers' className='mx-4 my-6 md:my-0 cursor-pointer'>
          Careers
        </Link>
        <Link to='/open-source' className='mx-4 my-6 md:my-0 cursor-pointer'>
          Open Sources
        </Link>
      </div> */}
      {/* <div className="flex flex-col md:flex-row">
        <span className="mx-4 cursor-pointer">FB.</span>
        <span className="mx-4 cursor-pointer">LN.</span>
        <span className="mx-4 cursor-pointer">TW.</span>
        <span className="mx-4 cursor-pointer">IG.</span>
      </div> */}
    </div>
    <div className="flex flex-col md:flex-row items-start flex-wrap justify-center text-xs my-4 mb-8">
      <div className="flex m-4 items-center">
        <img src={location_icon} className="w-8" alt="Location logo" />
        <p className="mx-2 text-white">
          <span className="block text-blue-500  text-md font-bold">
            Location:
          </span>
          Pairing OÜ Sakala 7-2, 10141 Tallinn
        </p>
      </div>
      <div className="flex m-4 items-center">
        <img src={email_icon} className="w-8" alt="email icon" />
        <p className="mx-2 text-white ">
          <span className="block text-blue-500  text-md font-bold">Email:</span>
          info@pairing.dev
        </p>
      </div>
      <div className="flex m-4 items-center">
        <img src={phone_icon} className="w-8" alt="phone logo" />
        <p className="mx-2 text-white">
          <span className="block text-blue-500 text-md font-bold">Phone:</span>
          +1 929 260 1944
        </p>
      </div>
    </div>
    <div className="border-t border-gray-600 text-gray-300 font-light text-sm text-center py-4 mt-8">
      @ 2022 Pairing OÜ All Right Reserved
    </div>
  </footer>
)

export default Footer
