/* eslint-disable react/jsx-props-no-spreading */
import { Link as GatsbyLink } from 'gatsby'
import React from 'react'
import PropTypes from '../../utils/PropTypes'
import classnames from 'classnames'

const classes = {
  base: {
    btn: 'focus:outline-none transition ease-in-out duration-300 rounded-md',
  },
  disabled: 'opacity-50 cursor-not-allowed',
  pill: 'rounded-full',
  size: {
    small: 'px-2 py-1 text-sm',
    normal: 'px-4 py-2',
    medium: 'px-3 py-1 sm:px-4 sm:py-2 text-md rounded-full',
    large: 'px-8 py-3 text-lg',
    extra: 'px-10 py-4 text-lg md:text-2xl',
    extra_2x: 'px-10 py-4 text-4xl',
  },
  variant: {
    primary:
      'bg-primary hover:bg-teal-dark focus:ring-2 focus:ring-teal-normal focus:ring-opacity-50 text-white',
    secondary:
      'bg-gray-200 hover:bg-gray-800 focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 text-gray-900 hover:text-white',
    danger:
      'bg-red-500 hover:bg-red-800 focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 text-white',
  },
}

const propTypes = {
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  children: PropTypes.node,
  partiallyActive: PropTypes.bool,
  to: PropTypes.string.isRequired,
}

const defaultProps = {
  activeClassName: null,
  children: null,
  className: null,
  partiallyActive: false,
}

const INTERNAL_LINK_PATTERN = /^\/(?!\/)/
const FILE_LINK_PATTERN = /\.[0-9a-z]+$/i

const Link = ({
  activeClassName,
  children,
  className,
  partiallyActive,
  variant,
  type = '',
  size,
  to,
  ...rest
}) => {
  if (!INTERNAL_LINK_PATTERN.test(to)) {
    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className={classnames(`
      ${classes.base[type]}
      ${classes.size[size]}
      ${classes.variant[variant]}
      ${className}
  `)}
        {...rest}
      >
        {children}
      </a>
    )
  }

  if (FILE_LINK_PATTERN.test(to)) {
    return (
      <a href={to} className={className} {...rest}>
        {children}
      </a>
    )
  }

  return (
    <GatsbyLink
      to={to}
      activeClassName={activeClassName}
      className={classnames(`
      ${classes.base[type]}
      ${classes.size[size]}
      ${classes.variant[variant]}
      ${className}
  `)}
      partiallyActive={partiallyActive}
      {...rest}
    >
      {children}
    </GatsbyLink>
  )
}

Link.propTypes = propTypes
Link.defaultProps = defaultProps

export default Link
