import './src/styles/main.css'
import 'typeface-merriweather'
import 'typeface-merriweather-sans'

import '@fontsource/roboto'
import '@fontsource/lato'
import '@fontsource/alegreya'
import '@fontsource/poppins'
import '@fontsource/poppins/300.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'

import '@fontsource/open-sans/600.css'

import React from 'react'
import ThemeContextProvider from './src/contexts/ThemeContext'
export const wrapRootElement = ({ element }) => {
  return <ThemeContextProvider>{element}</ThemeContextProvider>
}
