import * as React from 'react';
import Header from './Header';
import Footer from './Footer';
import PropTypes from '../utils/PropTypes';
import { ThemeContext } from '../contexts/ThemeContext';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

const Layout = ({ children, location }) => {
  const { theme /* , setTheme */ } = React.useContext(ThemeContext);
  return (
    <>
      <div
        className={`${
          theme === 'dark'
            ? 'dark bg-dark_bg-normal border-b border-gray-600 text-white'
            : ''
        } flex flex-col`}
      >
        {children.key !== '/signup/' && <Header location={location} />}
        <main className='space-y-6 md:space-y-20 md:mx-0 flex flex-col'>
          {children}
        </main>
        {children.key !== '/signup/' && <Footer />}
      </div>
    </>
  );
};

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default Layout;
