// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-book-jsx": () => import("./../../../src/pages/book.jsx" /* webpackChunkName: "component---src-pages-book-jsx" */),
  "component---src-pages-book-thanks-jsx": () => import("./../../../src/pages/book/thanks.jsx" /* webpackChunkName: "component---src-pages-book-thanks-jsx" */),
  "component---src-pages-building-jsx": () => import("./../../../src/pages/building.jsx" /* webpackChunkName: "component---src-pages-building-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-casestudies-jsx": () => import("./../../../src/pages/casestudies.jsx" /* webpackChunkName: "component---src-pages-casestudies-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-join-standup-jsx": () => import("./../../../src/pages/join-standup.jsx" /* webpackChunkName: "component---src-pages-join-standup-jsx" */),
  "component---src-pages-open-processes-jsx": () => import("./../../../src/pages/open-processes.jsx" /* webpackChunkName: "component---src-pages-open-processes-jsx" */),
  "component---src-pages-outsourcing-jsx": () => import("./../../../src/pages/outsourcing.jsx" /* webpackChunkName: "component---src-pages-outsourcing-jsx" */),
  "component---src-pages-signup-jsx": () => import("./../../../src/pages/signup.jsx" /* webpackChunkName: "component---src-pages-signup-jsx" */),
  "component---src-templates-blog-page-jsx": () => import("./../../../src/templates/blog-page.jsx" /* webpackChunkName: "component---src-templates-blog-page-jsx" */),
  "component---src-templates-casestudy-page-jsx": () => import("./../../../src/templates/casestudy-page.jsx" /* webpackChunkName: "component---src-templates-casestudy-page-jsx" */)
}

